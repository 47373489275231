<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Campaign report</div>
          <span class="subtitle-1 light-grey"
            >Filter report. Shows current month by default.
          </span>
        </div>
        <v-spacer></v-spacer>

        <!--Larger than mobile button-->
        <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" left>
          <template v-slot:activator="{ on }">
            <v-badge overlap color="badges">
              <template v-if="filtersCount > 0" v-slot:badge>{{
                filtersCount
              }}</template>
              <v-btn
                v-on="on"
                icon
                outlined
                color="primary"
                @click="
                  filtersTmp = cloneDeep(filters);
                  showFiltersDialog = true;
                "
              >
                <v-icon small>fas fa-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Filter</span>
        </v-tooltip>

        <!--Mobile button-->
        <v-btn
          color="primary"
          class="hidden-sm-and-up"
          fixed
          bottom
          right
          fab
          @click="
            filtersTmp = cloneDeep(filters);
            showFiltersDialog = true;
          "
        >
          <v-icon>fas fa-filter</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <campaign-stats-data-table
          :items="stats"
          :loading="loading"
        ></campaign-stats-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showFiltersDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filter report
        </v-card-title>
        <v-card-text>
          <influencer-autocomplete
             v-model="filtersTmp.influencer_uuid"
             :return-object="false"
          ></influencer-autocomplete>
          <date-picker
            v-model="filtersTmp.start_date"
            label="Start date"
            cancel-button
            reset-button
          ></date-picker>
          <date-picker
            v-model="filtersTmp.end_date"
            label="End date"
            cancel-button
            reset-button
          ></date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              filters = cloneDeep(filtersTmp);
              showFiltersDialog = false;
            "
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers.js";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import DatePicker from "@/components/common/filters/DatePicker";
import CampaignStatsDataTable from "../../../data-tables/CampaignStatsDataTable";
import { mapActions } from "vuex";
import InfluencerAutocomplete from "../../../autocompletes/InfluencerAutocomplete";

export default {
  components: {InfluencerAutocomplete, CampaignStatsDataTable, DatePicker },
  mixins: [search_filters_url],
  filters: { local_numbers },
  data: () => ({
    stats: [],
    loading: false,
    showFiltersDialog: false,
    filtersTmp: {},
    filters: {}
  }),
  computed: {
    filtersCount() {
      return Object.keys(this.filters).length;
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.setUrlSearchFilters(this.filters);
        this.getStats();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core", { loadStats: "getStats" }),

    getStats() {
      this.loading = true;

      let params = {
        start_date: this.filters.start_date,
        end_date: this.filters.end_date,
        group: ["campaign", "business"]
      };

      if(this.filters.influencer_uuid) {
        params.influencer_uuid = this.filters.influencer_uuid;
      }
      this.loadStats(params).then(
        stats => {
          this.loading = false;
          this.stats = stats;
        },
        () => {
          this.setSnackError("Could not fetch data");
        }
      );
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();

    if (Object.keys(this.filters).length === 0) {
      this.filters.start_date = this.$moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      this.filters.end_date = this.$moment()
        .endOf("month")
        .format("YYYY-MM-DD");
    }
    this.getStats();
  }
};
</script>
